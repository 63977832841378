import { useState, useEffect } from "react";
import axios from "axios";
import { useMicrophoneAndCameraTracks } from "./settings.js";
import { createClient } from "agora-rtc-react";
import { Grid } from "@material-ui/core";
import Video from "./Video";
import Controls from "./Controls";
import "./App.css";

export default function VideoCall(props) {
  const { setInCall } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [small, setSmall] = useState(false);
  const { ready, tracks } = useMicrophoneAndCameraTracks({
    encoderConfig: "720p_1",
  });
  const [veiwVideo, setViewVideo] = useState(true);

  const viewSmall = () => {
    setSmall((prevValue) => !prevValue);
  };

  const [config, setConfig] = useState({
    mode: "rtc",
    codec: "vp8",
    appId: "",
    token: null,
    channelName: "",
    uid: 1,
  });

  const [client, setClient] = useState(null);

  const gridStyle = {
    position: "absolute",
    zIndex: "99",
    bottom: "20px",
    margin: "0 auto",
    width: "100%",
    textAlign: "center",
  };

  const searchParams = new URLSearchParams(document.location.search);
  const id = searchParams.get("ref_id");
  const apiKey = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiKey}/api/get-credential-vrs?ref_id=${id}`
        );
        const dataResponse = response?.data?.data;
        const newConfig = {
          mode: "rtc",
          codec: "vp8",
          appId: dataResponse?.app_id,
          token: dataResponse?.token,
          channelName: dataResponse?.channel_name,
          uid: dataResponse?.user_id,
        };

        setConfig(newConfig);
        setClient(
          createClient({
            mode: "rtc",
            codec: "vp8",
            appId: newConfig.appId,
            token: newConfig.token,
          })
        );
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let init = async (name) => {
      if (!client || !ready || !tracks) return;
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, mediaType) => {
        if (mediaType === "audio") {
          if (user.audioTrack) user.audioTrack.stop();
        }
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      try {
        await client.join(config.appId, name, config.token, config.uid);
      } catch (error) {
        console.log("error");
      }

      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      try {
        init(config.channelName);
      } catch (error) {
        console.log(error);
      }
    }
  }, [config, client, ready, tracks]);

  const [timeLeft, setTimeLeft] = useState(30 * 60);
  useEffect(() => {
    if (!start) return;

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [start]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Grid container direction="column" style={{ height: "100%" }}>
      <h2
        style={{
          position: "absolute",
          zIndex: "99",
          left: "47.5%",
          color: "white",
          textShadow: "0px 0px 20px black",
          backgroundColor: timeLeft <= 300 ? "red" : "green",
          borderRadius: "10px",
          padding: "5px 10px",
        }}
      >
        {formatTime(timeLeft)}
      </h2>
      {timeLeft <= 300 ? (
        <h5 class="alert">Waktu panggilan tersisa kurang dari 5 Menit</h5>
      ) : null}
      <Grid item style={gridStyle}>
        {ready && tracks && (
          <Controls
            tracks={tracks}
            setStart={setStart}
            client={client}
            setInCall={setInCall}
            setView={viewSmall}
            setViewVideo={setViewVideo}
            timeUsedMs={(30 * 60 - timeLeft) * 1000}
          />
        )}
      </Grid>
      <Grid item style={{ height: "100%" }}>
        {start && tracks && (
          <Video
            tracks={tracks}
            users={users}
            setView={small}
            veiwVideo={veiwVideo}
          />
        )}
      </Grid>
    </Grid>
  );
}
