import { useState } from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
// import WideVideoIcon from "@material-ui/icons/Crop32Outlined";
import CallEndIcon from "@material-ui/icons/CallEnd";
// import { IcoSplit } from "./assets/images/IcoSplit";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import axios from "axios";

export default function Controls(props) {
  const searchParams = new URLSearchParams(document.location.search);
  const id = searchParams.get("ref_id");
  const apiKey = process.env.REACT_APP_BASE_URL;
  const { tracks, setStart, setInCall, client, setViewVideo, timeUsedMs } =
    props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  // const { tracks, setStart, setInCall, setView, client } = props;
  // const [halfState, setHalfState] = useState(false);

  const buttonStyle = {
    borderRadius: "50px",
    width: "46px",
    height: "46px",
    padding: "5px",
    minWidth: "unset",
  };

  const justifyCenter = {
    justifyContent: "center",
  };

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
      setViewVideo(!trackState.video);
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    // let response = await axios.get(`${apiKey}/api/end-call-vrs?ref_id=${id}`);
    let response = await axios.get(
      `${apiKey}/api/end-call-vrs?ref_id=${id}&duration=${timeUsedMs}`
    );
    if (response) {
      handleRedirect();
    }
  };

  const handleRedirect = () => {
    const redirectUrl = `${apiKey}/vrs-service`;
    window.open(redirectUrl, "_self");
    window.close();
  };

  // const handleView = () => {
  //   setView();
  //   setHalfState((prevValue) => !prevValue);
  // };

  return (
    <Grid container spacing={2} alignItems="center" style={justifyCenter}>
      <Grid item>
        <Tooltip title={trackState.audio ? "Mute" : "Unmute"}>
          <Button
            variant="contained"
            style={buttonStyle}
            color={trackState.audio ? "default" : "secondary"}
            onClick={() => mute("audio")}
          >
            {trackState.audio ? <MicIcon /> : <MicOffIcon />}
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="End Call">
          <Button
            variant="contained"
            style={buttonStyle}
            className="btn-middle"
            color="secondary"
            onClick={() => leaveChannel()}
          >
            <CallEndIcon />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={trackState.video ? "Off camera" : "On camera"}>
          <Button
            variant="contained"
            style={buttonStyle}
            color={trackState.video ? "default" : "secondary"}
            onClick={() => mute("video")}
          >
            {trackState.video ? <VideocamIcon /> : <VideocamOffIcon />}
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
